/* eslint-disable */
import { createContext } from 'react';

export const MenuContext = createContext({
  showMenu: false,
  toggleShowMenu: () => {},
  setDidChange: (element: unknown) => {
    console.log(element);
  },
});
