/* eslint-disable */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { calculateClassName } from '../ui';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant: 'primary' | 'secondary' | 'danger';
  loading?: boolean;
  fullWidth?: boolean;
  disabledClassName?: string;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, loading = false, disabled, fullWidth, className, disabledClassName, ...props }, ref) => {
    const button = `px-4 py-2 font-semibold border border-transparent rounded-lg shadow-md focus:outline-none focus:border-indigo-700`;

    const styles = {
      primary: `font-semibold text-blue-900 bg-yellow-400`,
      secondary: `text-indigo-700 bg-white border-gray-200`,
      danger: `bg-red-500 text-white`,
    };

    disabled = disabled || loading;

    const disabledColor = disabled
      ? props.variant === 'primary'
        ? `bg-yellow-300`
        : props.variant === 'secondary'
        ? `bg-white`
        : `bg-red-400`
      : ``;

    const withFullWidth = fullWidth ? `w-full` : ``;

    return (
      <button
        ref={ref}
        {...props}
        disabled={disabled}
        className={calculateClassName([
          button,
          styles[props.variant],
          disabledColor,
          withFullWidth,
          className,
          disabled ? disabledClassName : '',
        ])}
      >
        {loading ? (
          <div style={{ fontSize: '1em' }}>
            <FontAwesomeIcon icon={faCircleNotch} spin />
          </div>
        ) : (
          children
        )}
      </button>
    );
  },
);

Button.defaultProps = {
  variant: 'secondary',
};
