import React from 'react';
import { calculateClassName } from '../ui';

export type Ref = HTMLTextAreaElement;

interface TextareaProps extends React.ComponentPropsWithoutRef<'textarea'> {
  // eslint-disable-next-line
  error?: { [key: string]: any };
  spacing?: 'tight' | 'small' | 'normal';
}

const Error = ({ error }: { error: { [key: string]: string } }) => (
  <p className="absolute text-red-500 text-xs italic">
    {error.message.length ? error.message : 'Please fill out this field.'}
  </p>
);

export const Textarea = React.forwardRef<Ref, TextareaProps>(({ error, spacing, className, ...props }, ref) => {
  const base = `w-full px-3 py-2 border border-gray-300 rounded shadow-inner outline-none focus:border-indigo-700`;

  const spacingStyle: { [key: string]: string } = {
    tight: `mb-0`,
    small: `mb-2`,
    normal: `mb-5`,
  };

  const errorStyle = `border rounded border-red-500 focus:border-red-500 focus:bg-white`;
  return (
    <div className={calculateClassName('relative', spacingStyle[spacing ?? 'normal'], className)}>
      <textarea ref={ref} {...props} className={calculateClassName([base, error ? errorStyle : ''])} />
      {error && <Error error={error} />}
    </div>
  );
});

Textarea.displayName = 'Textarea';
