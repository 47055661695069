import toast from 'react-hot-toast';

/**
 * Show an error toast.
 *
 * @param message The user oriented error message
 */
function errorToast(message: string) {
  toast.error(message);
}

/**
 * Show a success toast.
 *
 * @param message The user oriented success message
 */
function successToast(message: string) {
  toast.success(message);
}

/**
 * Hook to show success and error toasts.
 */
export function useToast() {
  return {
    errorToast,
    successToast,
  };
}

/**
 * Presents, to the user, an error ocurred during a mutation request to the backend.
 *
 * This function does 3 things:
 *
 * - Print the exception and payload to the JavaScript console
 * - Show an error message to the user with a toast
 *
 * @param err The exception or rejected Promise value
 * @param payload The payload that was sent to the backend
 */
export function presentMutationError(err: any, payload: unknown) {
  console.error(
    '%cMutation Error%c\n    %o\n\n    %cPayload:%c %o\n\n    %cResponse data:%c %o',
    'font-weight: bold',
    '',
    err,
    'color: black',
    '',
    payload,
    'color: black',
    '',
    err.response?.data,
  );

  // Try to extract the message from the backend error response
  // (possibly a validation error that we should have caught in the frontend).
  const userMessagePieces = [err.response?.data?.[0]?.instancePath, err.response?.data?.[0]?.message];
  let userMessage = userMessagePieces.filter(Boolean).join(' ').trim();

  // Default message if none was given
  if (!userMessage) {
    userMessage = 'Something Went Wrong';
  }
  errorToast(userMessage);
}
