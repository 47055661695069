import React from 'react';
import { calculateClassName, Container } from '../index';

export const TitleBar = ({
  title,
  action,
  className,
}: {
  title: string;
  action?: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={calculateClassName('py-6  shadow bg-white', className)}>
      <Container size="large">
        <div className="flex items-center justify-between">
          <div className="text-3xl leading-tight font-bold">{title}</div>
          <div>{action}</div>
        </div>
      </Container>
    </div>
  );
};
