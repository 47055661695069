import { createContext } from 'react';

const ModalContext = createContext<{ isOpen: boolean } | undefined>(undefined);
const ModalProvider = ModalContext.Provider;

//function useModalContext() {
//const context = useContext(ModalContext);
//if (!context) {
//throw new Error('no context');
//}
//return context;
//}

export { ModalProvider };

export interface UseModalProps extends React.ComponentPropsWithoutRef<'div'> {
  isOpen: boolean;
}

export function useModal(props: UseModalProps) {
  const { isOpen } = props;
  return {
    isOpen,
  };
}
