/* eslint-disable */
import React from 'react';
import { calculateClassName } from '../ui';

type Ref = HTMLSpanElement;

export interface BadgeProps extends React.ComponentPropsWithoutRef<'span'> {
  variant: 'warning' | 'success' | 'disabled' | 'waiting' | 'screening';
}

export const Badge = React.forwardRef<Ref, BadgeProps>(function Badge({ className, ...props }, ref) {
  const base = `px-2 text-xs font-semibold rounded-full`;

  const styles = {
    warning: `text-yellow-900 bg-yellow-200`,
    success: `text-green-800 bg-green-200`,
    disabled: `text-gray-700 bg-gray-200`,
    waiting: `text-blue-700 bg-blue-100`,
    screening: `bg-purple-100 text-purple-800`,
  };

  return <span ref={ref} {...props} className={calculateClassName([base, styles[props.variant], className])} />;
});
