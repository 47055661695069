/* eslint-disable */
import React from 'react';
import { calculateClassName } from '../ui';

type Ref = HTMLDivElement;

export const Card = React.forwardRef<Ref, React.ComponentPropsWithoutRef<'div'>>(function Card(
  { className, ...props },
  ref,
) {
  const base = `px-4 py-6 bg-white rounded-lg shadow`;

  return <div ref={ref} {...props} className={calculateClassName([base, className])} />;
});
