/* eslint-disable */
import {
  cloneElement,
  ButtonHTMLAttributes,
  HTMLAttributes,
  ReactElement,
  ReactNode,
  useState,
  useContext,
  useRef,
  Ref,
  createContext,
} from 'react';

import { getValidChildren, mergeRefs } from '@expressable/utils';
import { useDescendant, useDescendants } from './use-descentant';
import { useControllableState } from './use-controllable';

export type UseTabsReturn = Omit<ReturnType<typeof useTabs>, 'htmlProps'>;

const TabsContext = createContext<UseTabsReturn | undefined>(undefined);
const TabsProvider = TabsContext.Provider;

function useTabsContext() {
  const context = useContext(TabsContext);
  if (!context) {
    throw new Error('no context');
  }
  return context;
}

export { TabsProvider };

type Child = ReactElement<any>;

export interface UseTabsProps {
  index?: number;
  defaultIndex?: number;
  onChange?: (index: number) => void;
  id?: string;
}

export function useTabs(props: UseTabsProps) {
  const { defaultIndex, onChange, index, ...htmlProps } = props;

  const [selectedIndex, setSelectedIndex] = useControllableState<number>({
    defaultValue: defaultIndex ?? 0,
    value: index,
    onChange,
    propsMap: {
      value: 'index',
      defaultValue: 'defaultIndex',
      onChange: 'onChange',
    },
  });

  const id = props.id ?? '342343252345';
  const domContext = useDescendants();

  return {
    id,
    selectedIndex,
    setSelectedIndex,
    domContext,
    htmlProps,
  };
}

export interface UseTabPanelsProps {
  children?: ReactNode;
}

export function useTabPanels<P extends UseTabPanelsProps>(props: P) {
  const { id, selectedIndex } = useTabsContext();
  const validChildren = getValidChildren(props.children);

  const children = validChildren.map((child: React.ReactElement, index: number) =>
    cloneElement(child as Child, {
      isSelected: index === selectedIndex,
      id: makeTabPanelId(id, index),
    }),
  );
  return { ...props, children };
}

export interface UseTabListProps {
  children?: ReactNode;
  className?: string;
}

export function useTabList<P extends UseTabListProps>(props: P) {
  const { setSelectedIndex, selectedIndex } = useTabsContext();
  const validChildren = getValidChildren(props.children);
  const SelectOptions = validChildren.map((child: React.ReactElement, index: number) => {
    return {
      label: child.props.children,
      value: index,
    };
  });
  return { ...props, SelectOptions, setSelectedIndex, selectedIndex };
}

export interface UseClickableProps extends HTMLAttributes<Element> {
  isDisabled?: boolean;
  isFocusable?: boolean;
  clickOnEnter?: boolean;
  clickOnSpace?: boolean;
  ref?: Ref<HTMLElement>;
}

export function useTab<P extends UseClickableProps>(props: P): ButtonHTMLAttributes<any> {
  const { id, ...htmlProps } = props;

  const { setSelectedIndex, domContext, selectedIndex } = useTabsContext();
  const href = id ? `#${id}` : ``;
  const ref = useRef<HTMLElement>(null);

  const index = useDescendant({
    context: domContext,
    element: ref.current,
  });

  const isSelected = index === selectedIndex;

  const onClick = () => {
    setSelectedIndex(index);
  };

  return {
    ...htmlProps,
    href,
    ref: mergeRefs(ref, props.ref),
    'aria-selected': isSelected ? true : undefined,
    onClick,
  };
}
export type Dict<T = any> = Record<string, T>;

export function useTabPanel(props: Dict) {
  const { isSelected, id, ...htmlProps } = props;

  return {
    ...htmlProps,
    isSelected,
    role: 'tabpanel',
    hidden: !isSelected,
    id,
  };
}
function makeTabPanelId(id: string, index: number) {
  return `${id}--tabpanel-${index}`;
}
