import React, { useContext, useState } from 'react';
import { Button, ButtonProps, calculateClassName, Link, UILinkProps } from '../index';
import { MenuContext } from './use-menu';

export type ButtonRef = HTMLButtonElement;

export type LinkRef = HTMLAnchorElement;

export const MenuButton = React.forwardRef<ButtonRef, ButtonProps>(function MenuButton({ className, ...props }, ref) {
  const menuContext = useContext(MenuContext);
  const base = `px-4 py-2 font-semibold border border-transparent rounded-lg shadow-md focus:outline-none focus:border-indigo-700`;
  return (
    <Button
      onClick={menuContext.toggleShowMenu}
      ref={ref}
      {...props}
      className={calculateClassName([base, className])}
    />
  );
});

export const MenuLink = React.forwardRef<LinkRef, UILinkProps>(function MenuLink({ className, ...props }, ref) {
  const menuContext = useContext(MenuContext);
  const base = `font-semibold`;
  return (
    <Link
      to="#"
      onClick={menuContext.toggleShowMenu}
      ref={ref}
      {...props}
      className={calculateClassName([base, className])}
    />
  );
});

export type DivRef = HTMLDivElement;

export const MenuList = React.forwardRef<DivRef, React.ComponentPropsWithoutRef<'div'>>(function MenuList(
  { className, ...props },
  ref,
) {
  const menuContext = useContext(MenuContext);
  return menuContext.showMenu ? (
    <div
      className={calculateClassName('shadow-md bg-white rounded-lg absolute left-0 z-10 py-2 mt-2', className)}
      ref={ref}
      {...props}
    ></div>
  ) : null;
});

export interface MenuItemProps extends Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange'> {
  value?: unknown;
}

export const MenuItem = React.forwardRef<DivRef, MenuItemProps>(function MenuItem(props, ref) {
  const { value, className, ...htmlProps } = props;
  const menuContext = useContext(MenuContext);
  return (
    <div
      onClick={() => menuContext.setDidChange(value)}
      className={calculateClassName('py-1 px-5 font-semibold hover:bg-indigo-100 cursor-pointer', className)}
      ref={ref}
      {...htmlProps}
    ></div>
  );
});

export interface MenuProps extends Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange'> {
  onChange?: (e: unknown) => void;
}

export const Menu = React.forwardRef<DivRef, MenuProps>(function Menu(props, ref) {
  const { onChange, className, ...htmlProps } = props;
  const [showMenu, setShowMenu] = useState(false);
  const [didChange, setDidChange] = useState<false | unknown>(false);

  React.useEffect(() => {
    if (didChange) {
      setShowMenu(false);
      onChange(didChange);
      setDidChange(false);
    }
  }, [didChange, onChange]);

  return (
    <MenuContext.Provider
      value={{
        showMenu,
        toggleShowMenu: () => setShowMenu(!showMenu),
        setDidChange: (element: unknown) => setDidChange(element),
      }}
    >
      <div className={calculateClassName('relative text-indigo-700', className)} ref={ref} {...htmlProps}></div>
    </MenuContext.Provider>
  );
});
