/* eslint-disable */
import React from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { calculateClassName } from '../ui';

type Ref = HTMLAnchorElement;
type HyperlinkProps = LinkProps & React.RefAttributes<HTMLAnchorElement>;

export interface UILinkProps extends HyperlinkProps {
  loading?: boolean;
}

export const Link = React.forwardRef<Ref, UILinkProps>(
  ({ className, href, loading = false, children, ...props }, ref) => {
    const base = `text-indigo-700`;
    if (href) {
      return (
        <a ref={ref} {...props} className={calculateClassName([base, className])}>
          {children}
        </a>
      );
    } else {
      return (
        <RouterLink
          className={calculateClassName([base, className])}
          {...props}
          style={{ pointerEvents: loading ? 'none' : 'auto' }}
        >
          {loading ? (
            <div style={{ fontSize: '1.3em' }}>
              <FontAwesomeIcon icon={faCircleNotch} spin />
            </div>
          ) : (
            children
          )}
        </RouterLink>
      );
    }
  },
);

Link.displayName = 'Link';
